export const selectDiagramPart = {
  order: 8,
  name: "Select a property diagram part",
  keywords: "datapoint multiple",
  subcategory: "Properties",
  markdown: `# Select a property diagram part

  To access details about various parts of a property, you select those parts in the {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %} by clicking on them. Air rights, floor areas, building levels, ownership units, leaseholds, land coverings, fee parcels, reciprocal easements, {% inlineAppIcon iconName="sparkles" /%} enhancements, {% inlineAppIcon iconName="cube" /%} ownership units, {% inlineAppIcon iconName="layout" /%} and floor area layouts can all be selected in this way.

  ## How to do it

  {% tablessContainer %}
  1. Visit a {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}property diagram{% /inlineRouterLink %}.
  2. Click on the desired part of the diagram.
  {% /tablessContainer %}

  ## What happens

  Once a diagram part is selected, its corresponding {% inlineRouterLink %}datapoint{% /inlineRouterLink %} appears in the control panel. When fee parcels and land coverings are selected, their corresponding {% inlineRouterLink articleId="map-markers" %}map markers{% /inlineRouterLink %} (if present) also turn orange and pulse.

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/propertyDiagramSelection0.mp4" marginStyles="my-4" /%}

  ## Selecting buildings that contain floor areas

  To select a {% inlineRouterLink articleId="add-a-land-covering" %}land covering{% /inlineRouterLink %} that is filled with {% inlineRouterLink articleId="add-a-floor-area" %}floor areas{% /inlineRouterLink %}, point at the top of the land covering block when you click.

  ![Selectable land covering with floor area](https://assets.towerhunt.com/site/selectLandCoveringWithFloorAreas0.png)

  ## Selecting multiple building levels

  To quickly select a range of building levels, select the top- or bottom-most level and then {% inlineKeyboardShortcut %}Shift{% /inlineKeyboardShortcut %} + {% inlineAppIcon iconName="cursor" /%}left click on the building at the other end of the range. This is useful in tall buildings instead of clicking each building level individually.

  {% inlineVideo videoSource="https://assets.towerhunt.com/site/selectMultipleBuildingLevels0.mp4" marginStyles="my-4" /%}

  ## Placing new datapoints on the diagram

  Many of the commands that create new datapoints ask you to choose where they belong on the property. The diagram uses colored outlines and shading to indicate your choices.

  {% callout type="check" %}
  You always have the chance to review your selections and either cancel or save. See {% inlineRouterLink articleId="property-diagram-interactions" sectionName="Primary selection commands" %}primary selection commands{% /inlineRouterLink %} for more detail.
  {% /callout %}

  ### Selectable parts

  If you can choose a part of the diagram, it is wrapped in a thick dashed purple border.

  ![Selectable diagram part](https://assets.towerhunt.com/site/selectableDiagramParts0.png)

  ### Selected parts

  If you have chosen a part of the diagram, it is filled in green and is wrapped in a dashed green border.

  ![Selected diagram part](https://assets.towerhunt.com/site/selectedDiagramParts0.png)
  `,
};
